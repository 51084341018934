export function lazyHydrate(componentImport, componentName) {
    let userHasInteracted = false;

    const initializeComponent = (componentSelector, module) => {
        const blocks = document.querySelectorAll(componentSelector);
        const Component = module.default;

        let ssrProps = false;

        blocks.forEach(block => {

            if (process.env.NODE_ENV !== 'production') {
                console.log(`[Svelte-WP-Kit] hydrating: ${componentName}`);
            }

            const id = block.getAttribute('data-svelte-wp-component-id');

            if (module.__getSSRProps !== undefined) {
                ssrProps = module.__getSSRProps(id);
            }

            let params = {
                target: block,
                hydrate: true
            }

            if (ssrProps !== false) { params.props = ssrProps; }

            new Component(params);
        });
    };

    const onUserInteraction = () => {
        if (!userHasInteracted) {
            userHasInteracted = true;
            // Dynamically import the Svelte component
            componentImport().then(module => {
                initializeComponent(`[data-svelte-wp-component="${componentName}"]`, module);

                // Remove listeners after first interaction
                document.removeEventListener('mousemove', onUserInteraction);
                document.removeEventListener('keydown', onUserInteraction);
                document.removeEventListener('touchstart', onUserInteraction);
            }).catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    console.error("[Svelte-WP-Kit] Failed to load module:", error);
                }
            });
        }
    };

    document.addEventListener('DOMContentLoaded', function () {
        document.addEventListener('mousemove', onUserInteraction);
        document.addEventListener('keydown', onUserInteraction);
        document.addEventListener('touchstart', onUserInteraction);
    });
}